<template>
  <div class="grid gap-12 lg:grid-cols-[60%_auto_1fr]">
    <app-page-subsection title="Évènements exclusifs" :to="{ name: 'events' }">
      <ul class="flex flex-wrap gap-4">
        <li
          v-for="event in data.slice(0, 2)"
          :key="event.id"
          class="flex-auto basis-[300px]"
        >
          <event-card :event="event" :ratio="1" />
        </li>
      </ul>
    </app-page-subsection>

    <div class="hidden pt-16 lg:block">
      <app-divider class="!h-full" vertical />
    </div>

    <app-page-subsection title="Dernier articles" :to="{ name: 'blog' }">
      <ul class="grid gap-4">
        <li v-if="pinnedPost">
          <blog-post-card outlined :post="pinnedPost" />
        </li>

        <li v-for="post in otherPosts" :key="post.id">
          <blog-post-list-item :post="post" />
        </li>
      </ul>
    </app-page-subsection>
  </div>
</template>

<script lang="ts" setup>
const { data } = useUpcomingEventList();

const { data: posts } = useLazyFetch("/api/blog/posts", {
  server: false,
  query: {
    searchable: true,
    limit: 10,
  },
  default: () => [],
});

const pinnedPost = computed(
  () => posts.value?.find((post) => post.pinned) ?? posts.value?.[0],
);

const otherPosts = computed(
  () =>
    posts.value?.filter((post) => post.id !== pinnedPost.value?.id).slice(5) ??
    [],
);
</script>
