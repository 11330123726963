<template>
  <app-card-link-background :to="{ name: 'blog-id', params: { id: post.id } }">
    <app-card class="flex gap-3 p-3" outlined tag="figure">
      <app-image
        v-if="post.og_image"
        alt=""
        class="aspect-square rounded object-cover"
        :height="64"
        :src="post.og_image"
        :width="64"
      />
      <figcaption>
        <p class="line-clamp-2 text-sm font-semibold">
          {{ post.title }}
        </p>
        <p class="mt-1 flex items-center justify-between text-xs text-subtle">
          <blog-tag v-if="!!post.tags?.[0]" :tag="post.tags[0]" />
          <span v-else />

          {{ formattedDate }}
        </p>
      </figcaption>
    </app-card>
  </app-card-link-background>
</template>

<script lang="ts" setup>
import type { BlogPostPage } from "~~/model/blog.model";

const properties = defineProps<{
  post: BlogPostPage;
}>();

const formattedDate = useI18nDate(properties.post.created_at);
</script>
